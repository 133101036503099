import React, { memo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { Persist } from 'formik-persist'
import * as Yup from 'yup'
// import axios from 'axios'

import {
  Modal,
  ModalInner,
  ModalLink,
  ModalLinkWrap,
} from 'components/signup/components/Modal/Modal'
import Form, {
  FormField,
  FormError,
} from 'components/signup/components/Form/Form'
import Text from 'components/signup/components/Text/Text.styles'
import Input from 'components/signup/components/Input/Input'
import Button from 'components/signup/components/Button/Button.styles'
import { Row, Column } from 'components/signup/components/Grid/Grid.styles'
import postDataNoAuth from 'api/post-data-no-auth'
import Checkbox from 'components/signup/components/Checkbox/Checkbox'
import { AuthContext, ComponentWrapper } from '@stokr/components-library'
import { ProjectTypes } from 'constants/enums'
import fetchDataPublic from 'api/fetch-data-public'
import parse from 'html-react-parser'

const initialFormValues = {
  email: '',
  name: '',
  mailingList: false,
  mailingListDisabled: false,
  mailingListText: (
    <>
      You just want to be updated on the project. Subscribe here for further
      information going forward.
    </>
  ),
  privateInvestorList: false,
  privateInvestorListDisabled: false,
  privateInvestorListText: (
    <>
      You are a professional investor or an investor willing to invest in
      private offerings. Subscribe here to be contacted by a dedicated team to
      help you confirm your eligibility for this investment.
      <br /> <br />
      You are seeking to invest on your own initiative and any potential
      investments arose as a result of your direct inquiry and outreach.
    </>
  ),
  optionalCheckBox: false,
  optionalCheckBoxText: '',
  optionalCheckBoxChecked: false,
}

export const NewVentureModal = memo((props) => {
  const [formValues, setformValues] = useState(initialFormValues)
  const [successMessage, setSuccessMessage] = useState(undefined)
  const [errorMessage, seterrorMessage] = useState(null)
  const [checkboxes, setcheckboxes] = useState()
  const [checkedCheckboxes, setcheckedCheckboxes] = useState([])
  const [isActionLoading, setisActionLoading] = useState(false)

  const { user, setUser, checkIfPrivateInvestor, checkIfUserSubscribed } =
    React.useContext(AuthContext)

  useEffect(() => {
    fecthCheckboxes()
  }, [])

  useEffect(() => {
    //we need to check if userData is subscribed for the selected project
    //every time the userData or project is updated
    //check only if these userData properties weren't checked already
    const { project } = props
    if (!user || !(project && project._id)) return

    if (user.isPrivateInvestor === undefined) {
      checkIfPrivateInvestor(project)
    }
    if (user.isSubscribed === undefined) {
      checkIfUserSubscribed(project)
    }
  }, [user, props.project])

  useEffect(() => {
    //when the checkboxes are fetched, set the text of the checkboxes
    if (checkboxes) {
      let formValuesCopy = { ...formValues }
      Object.values(checkboxes).forEach((checkbox) => {
        if (checkbox.label === 'createPrivateInvestor') {
          formValuesCopy.privateInvestorListText = parse(checkbox.agreementText)
        } else if (checkbox.label === 'subscribeToProject') {
          formValuesCopy.mailingListText = parse(checkbox.agreementText)
        }
      })
      setformValues(formValuesCopy)
    }

    //change text of checkboxes if user is logged in and is already subscribed
    if (user) {
      let formValuesCopy = { ...formValues }
      formValuesCopy.email = user.email
      formValuesCopy.emailTouched = true

      formValuesCopy.name = user.username
      formValuesCopy.nameTouched = true
      if (user.isPrivateInvestor) {
        formValuesCopy.privateInvestorListText =
          'You are already registered on the whitelist.'
        formValuesCopy.privateInvestorList = true
        formValuesCopy.privateInvestorListDisabled = true
      } else if (
        user.privateInvestorStatus &&
        user.privateInvestorStatus !== 'Admitted'
      ) {
        //if private investor status is under review
        formValuesCopy.privateInvestorListText =
          'Your profile is under review. An account manager will reach out to you to confirm your eligibility.'
        formValuesCopy.privateInvestorList = true
        formValuesCopy.privateInvestorListDisabled = true
      }

      if (user.isSubscribed) {
        formValuesCopy.mailingList = true
        formValuesCopy.mailingListText =
          'You already subscribed to the email notification list.'
        formValuesCopy.mailingListDisabled = true
      }

      setformValues(formValuesCopy)
    }
  }, [user, checkboxes])

  const fecthCheckboxes = async () => {
    try {
      const response = await fetchDataPublic('compliance/get-checkbox-group', {
        group: 'newVentureModal',
      })
      if (response != null) {
        setcheckboxes(response)
      }
    } catch (error) {
      console.log('🚀 ~ file: NewVentureModal.js:111 ~ error:', error)
    }
  }

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Oops, that's not a valid address")
      .required('Oops, this can‘t be blank'),
    name: Yup.string().required('Oops, this can‘t be blank'),
  })

  const onSubmitButton = async (values) => {
    const { project } = props
    let dataToSend = {
      email: values.email,
      projectId: project._id,
      name: values.name,
      checkedCheckboxes,
      salesChannel: 'featuredAssets',
    }
    setisActionLoading(true)
    try {
      if (
        values.privateInvestorList &&
        !formValues.privateInvestorListDisabled
      ) {
        dataToSend.createPrivateInvestor = true
      }
      if (values.mailingList && !formValues.mailingListDisabled) {
        dataToSend.subscribeToProject = true
      }

      var response = await postDataNoAuth(
        'private-investor/public/create',
        dataToSend,
      )

      setSuccessMessage(
        <>
          Please continue onboarding, an account manager will reach out to you.
          <br />
          If you are an existing account holder, kindly log in and make sure
          your account is fully verified.
          <ComponentWrapper noPaddingHorizontal>
            <Button
              fluid
              onClick={() => {
                if (response?.privateInvestor?.user_id) {
                  window.location.href = `https://dashboard.${process.env.REACT_APP_WEBSITE_DOMAIN}/checklist`
                } else {
                  window.location.href = `/signup?email=${values.email}`
                }
              }}
            >
              Continue
            </Button>
          </ComponentWrapper>
        </>,
      )
      var userCopy = { ...user }
      if (response?.privateInvestor) {
        userCopy.privateInvestorStatus = response.privateInvestor.status
      }
      if (response?.subscribedToProject) {
        userCopy.isSubscribed = true
      }
      setUser(userCopy)
    } catch (error) {
      console.log('🚀 ~ error', error)
      //show error message from BE only for 406 error
      if (error?.response?.status === 406) {
        let errorParsed = error.response.data?.substring(7)
        seterrorMessage(
          project?.name === 'microstrategy' ? (
            <>
              You are already added to this project.
              <br /> <br />
              <ComponentWrapper noPaddingHorizontal>
                <Button
                  fluid
                  onClick={() => {
                    if (user?._id) {
                      window.location.href = `https://dashboard.${process.env.REACT_APP_WEBSITE_DOMAIN}/checklist`
                    } else {
                      window.location.href = `/signup`
                    }
                  }}
                >
                  Continue
                </Button>
              </ComponentWrapper>
            </>
          ) : (
            errorParsed
          ),
        )
      } else {
        seterrorMessage(
          <>
            Something went wrong. Please try again a bit later or contact us at{' '}
            <a href="mailto:support@stokr.io" style={{ color: 'inherit' }}>
              support@stokr.io
            </a>{' '}
          </>,
        )
      }
    } finally {
      setisActionLoading(false)
    }
  }

  const {
    title,
    description,

    isModalOpen,
    onModalClose,
    onFormSend,
    popupError,
    project,
  } = props

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={(e) => {
        setformValues((prevState) => ({
          ...prevState,
          mailingList: false,
          privateInvestorList: false,
          optionalCheckBox: false,
        }))
        onModalClose(e)
      }}
    >
      <Row>
        <Column part={8}>
          <ModalInner modalTop>
            <Text>
              <h3>
                {errorMessage ? 'OOPS..' : successMessage ? 'AWESOME!' : title}
              </h3>
              {!(successMessage || errorMessage) && <p>{description}</p>}
            </Text>
          </ModalInner>
          <ModalInner modalBot>
            {!project.isStatic && (
              <ModalLinkWrap>
                <ModalLink
                  as="button"
                  onClick={() => {
                    window.open(
                      `https://${process.env.REACT_APP_WEBSITE_DOMAIN}/${project?.name}`,
                      '_blank',
                    )
                  }}
                >
                  {'Learn more about '} {project?.tokenSymbol}
                </ModalLink>
              </ModalLinkWrap>
            )}
          </ModalInner>
        </Column>

        <Column part={8}>
          <ModalInner>
            {successMessage || errorMessage ? (
              <Text>
                <br />
                <br />
                <p>{errorMessage ? errorMessage : successMessage}</p>
              </Text>
            ) : (
              <Formik
                initialValues={formValues}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  onFormSend(values)
                  onSubmitButton(values)
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  setFieldValue,
                  setFieldTouched,
                }) => {
                  const onChangeWithTouch = (e) => {
                    const field = e.target
                    setFieldValue(field.name, field.value, false)
                    setFieldTouched(field.name)
                  }

                  const oneOfCheckbox =
                    (values.mailingList && !formValues.mailingListDisabled) ||
                    values.privateInvestorList

                  const optionalCheckBox = formValues.optionalCheckBox
                    ? values.optionalCheckBoxChecked
                    : true

                  if (formValues.email && !values.email) {
                    setFieldValue('email', formValues.email)
                  }

                  if (formValues.emailTouched && !touched.email) {
                    setFieldTouched('email')
                  }

                  if (formValues.name && !values.name) {
                    setFieldValue('name', formValues.name)
                  }
                  if (formValues.nameTouched && !touched.name) {
                    setFieldTouched('name')
                  }

                  if (formValues.mailingList && !values.mailingList) {
                    setFieldValue('mailingList', formValues.mailingList)
                  }
                  if (
                    formValues.privateInvestorList &&
                    !values.privateInvestorList
                  ) {
                    setFieldValue(
                      'privateInvestorList',
                      formValues.privateInvestorList,
                    )
                  }

                  //create custom handleCheckboxChange to add the checkbox to the checkedCheckboxes array
                  const handleCheckboxChange = (e) => {
                    handleChange(e)

                    //we set id of the checkbox to be the same string as checkbox label, so we can identify the checkbox
                    if (e.target.checked) {
                      setcheckedCheckboxes([...checkedCheckboxes, e.target.id])
                    } else {
                      setcheckedCheckboxes(
                        checkedCheckboxes.filter(
                          (item) => item !== e.target.id,
                        ),
                      )
                    }
                  }

                  var submitDisabled =
                    !touched.email ||
                    !!errors.email ||
                    !touched.name ||
                    !!errors.name ||
                    isActionLoading ||
                    !oneOfCheckbox ||
                    !optionalCheckBox

                  return (
                    <Form>
                      <ComponentWrapper noPadding>
                        {/* <Persist
                          name="new-venture-email-form"
                          isSessionStorage={true}
                        /> */}
                        <FormField>
                          {!user && (
                            <>
                              <Input
                                id="new-venture-email"
                                name="email"
                                type="email"
                                label="Your email"
                                value={values.email}
                                onChange={onChangeWithTouch}
                                onBlur={handleBlur}
                                error={!!errors.email}
                                touched={!!touched.email}
                              />
                              <FormError show={touched.email && errors.email}>
                                {errors.email}
                              </FormError>

                              <ComponentWrapper
                                noPaddingHorizontal
                                paddingVeticalHalf
                                noPaddingBottom
                              >
                                <Input
                                  id="new-venture-name"
                                  name="name"
                                  type="text"
                                  label="Your name"
                                  value={values.name}
                                  onChange={onChangeWithTouch}
                                  onBlur={handleBlur}
                                  error={!!errors.name}
                                  touched={!!touched.name}
                                />
                                <FormError show={touched.name && errors.name}>
                                  {errors.name}
                                </FormError>
                              </ComponentWrapper>
                            </>
                          )}

                          <FormField
                            style={{ marginTop: '1rem', marginBottom: -5 }}
                          >
                            <Checkbox
                              id={'createPrivateInvestor'} //same as checkbox label
                              name="privateInvestorList"
                              text={formValues.privateInvestorListText}
                              checked={values.privateInvestorList}
                              disabled={formValues.privateInvestorListDisabled}
                              onChange={handleCheckboxChange}
                              onBlur={handleBlur}
                            />
                          </FormField>

                          <FormField
                            style={{ marginTop: '2rem', marginBottom: -5 }}
                          >
                            <Checkbox
                              id="subscribeToProject"
                              name="mailingList"
                              text={formValues.mailingListText}
                              checked={values.mailingList}
                              disabled={formValues.mailingListDisabled}
                              onChange={handleCheckboxChange}
                              onBlur={handleBlur}
                            />
                          </FormField>

                          {formValues.optionalCheckBox && (
                            <FormField
                              style={{ marginTop: '2rem', marginBottom: -5 }}
                            >
                              <Checkbox
                                id="optionalCheckBox"
                                name="optionalCheckBoxChecked"
                                text={formValues.optionalCheckBoxText}
                                value={values.optionalCheckBoxChecked}
                                checked={values.optionalCheckBoxChecked}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </FormField>
                          )}
                          {project.type === ProjectTypes.FUND &&
                            project.name !== 'microstrategy' && (
                              <FormField
                                style={{ marginTop: '2rem', marginBottom: -5 }}
                              >
                                <p
                                  style={{
                                    margin: 0,
                                    fontSize: 12,
                                  }}
                                >
                                  <strong>
                                    The fund, as well as its fund manager(s) and
                                    affiliates, may not be subject to any
                                    registration or other local marketing or
                                    private placement laws in your jurisdiction.
                                  </strong>
                                </p>
                              </FormField>
                            )}
                        </FormField>
                      </ComponentWrapper>

                      <ComponentWrapper
                        noPaddingBottom
                        noPaddingHorizontal
                        center
                      >
                        <Button
                          type="submit"
                          disabled={
                            submitDisabled // fluid
                          }
                        >
                          register
                        </Button>
                      </ComponentWrapper>

                      <ComponentWrapper paddingVeticalHalf noPaddingHorizontal>
                        <FormError show={popupError}>{popupError}</FormError>
                      </ComponentWrapper>
                    </Form>
                  )
                }}
              </Formik>
            )}
          </ModalInner>
        </Column>
      </Row>
    </Modal>
  )
})

NewVentureModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
  onFormSend: PropTypes.func.isRequired,
  onModalSwitch: PropTypes.func,
}

export default NewVentureModal
